.about-section {
  position: absolute;
  font-size: 16px;
}

.slide-in {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 100vh;
  animation: slide-in 2.8s;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;
  transition: ease-in-out;
  gap: 10px;
  :first-child {
    padding-bottom: 1%;
    margin: 0 20px;
  }

  p {
    margin-block-end: 0px;
    font-size: 0.8rem;
  }
}

.float {
  z-index: -1;
  height: 100%;
  width: 100vw;
  background-color: lightblue;
}

#greeting {
  font-family: "Victorianna";
  color: #3c00ff;
  margin-block-end: 1.2em;
  margin-block-start: 1em;

  span {
    font-size: 3.815rem;
  }
}

@media only screen and(min-width: 900px) {
}

@media only screen and(max-width: 900px) {
  #greeting {
    span {
      font-size: 3.815rem;
    }
  }
}

@media only screen and (max-width: 450px) {
  .slide-in {
    p {
      font-size: 0.8em;
    }
  }
}

.about-link {
  animation: to-purple 1s;
  transition: ease-in;
}

@keyframes to-purple {
  0% {
    color: black;
  }

  100% {
    color: purple;
  }
}

@keyframes slide-in {
  0% {
    top: 100vh;
    opacity: 0%;
  }

  70% {
    top: 0vh;
    opacity: 50%;
  }

  100% {
    top: 2.5vh;
    opacity: 100%;
    border: 1px solid blue;
    padding: 40px 20px;
    box-shadow: inset 0 0 0 2px blue, 0px -10px 0 -3px #fff, 0px -10px #1fc11b,
      0px -20px 0 -3px #fff, 0px -20px #ffd913, 0px -30px 0 -3px #fff,
      0px -30px #f55, 0px -40px 0 -3px #fff, 0px -20px #f55;
  }
}
