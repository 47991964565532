.modal {
  display: flex;
  justify-content: center;
  gap: 5%;
  align-items: flex-start;
  max-height: 100%;
  padding-left: 5%;
  padding-right: 5%;
  height: 100%;
}
.modal img {
  -o-object-fit: contain;
     object-fit: contain;
  max-height: 70vh;
}
.modal .left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  max-width: 70%;
}
.modal button {
  background: none;
  border: 1px solid black;
}

.modal-img-container {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  .modal {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .modal button {
    color: black;
    height: 100%;
  }
  .modal .left {
    flex-direction: row;
    gap: 10%;
  }
  .modal .left > button {
    height: 50%;
  }
  .modal p {
    font-size: smaller;
  }
  .modal img {
    max-height: 60vh;
  }
}
@media only screen and (max-width: 450px) {
  .modal {
    padding-top: 5%;
  }
}/*# sourceMappingURL=modal.css.map */