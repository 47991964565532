.Projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-margin-top: 60px;
  padding-bottom: 20px;
  gap: 20px;
}
.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

@media only screen and (min-width: 1150px) {
  .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    grid-column-gap: 50px;
  }

  .Projects {
    padding-left: 5%;
    padding-right: 5%;
  }
}
