.ArtCard {
  width: 42.5vw;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  transition: ease-in-out 0.3s;
}
.ArtCard:hover {
  transform: scale(0.99);
}

.active-img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: white;
  animation: fadein 1.2s;
  transition: ease-in-out 0.3s;
}

.active-image-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 0px;
  padding: 0px;
  cursor: pointer;
  border-bottom: 1px solid purple;
  transition: ease-in 1s;
}

.thumbnail-container {
  border: 0.5px solid purple;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5em 0;
  gap: 2em;
}
.thumbnail-container button {
  height: 2.5em;
  width: 2.5em;
  border: 0px;
  padding: 2px;
  cursor: pointer;
  border-radius: 100%;
}
.thumbnail-container button:hover {
  border: 2px solid purple;
}
.thumbnail-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 100%;
}

@media only screen and (max-width: 600px) {
  .ArtCard {
    width: 100%;
  }
  .lr {
    width: 80vw;
  }
}
@media only screen and (min-width: 1100px) {
  .ArtCard {
    width: 100%;
  }
  .lr {
    width: 80vw;
  }
}
.hidden {
  display: none;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.ArtCard-Modal .active-img :hover {
  transform: none;
  opacity: initial;
}/*# sourceMappingURL=ArtCard.css.map */