* {
  box-sizing: border-box;
  font-size: 20px;
}

body {
  margin: 0;
  font-family: roboto-mono, monospace;
  font-weight: 200;
  font-style: normal;
}

button {
  cursor: pointer;
}

.about-section {
  display: flex;
  flex-direction: column;
  margin: 0 20vw;
  align-items: center;
  scroll-margin-top: 60px;

  @media screen and (max-width: 450px) {
    margin: 0 8vw;
  }
}

@font-face {
  font-family: "Victorianna";
  src: local("Victorianna"),
    url("../src/fonts/VictoriannaThint_Web/vtf_victorianna_thin-webfont.ttf")
      format("truetype");
}

.font-face-gm {
  font-family: "Victorianna";
  font-size: 3em;
  color: #3c00ff;
  margin-block-end: 0em;
}

p {
  font-family: roboto-mono, monospace;
  font-weight: 300;
  font-style: normal;
}

.rotate {
  animation: rotating 3s infinite linear;
  color: blue;
  font-size: larger;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: transform(359deg);
  }
}

#root > div > div.content > div.project-section > div:nth-child(6) {
  margin-bottom: 5%;
}

.card-button {
  transition: ease-in-out 0.2s;
  border: none;
  font-family: roboto-mono, monospace;
  font-weight: 400;
  font-style: normal;
  padding: 8px;
  margin-bottom: 5px;
  width: 75%;
  cursor: pointer;
  border-width: 0.1px;
  background-color: white;
  color: #2c3163;
  border-radius: 50%;
  font-size: medium;
  box-shadow: inset 0px 1px 0px 5px #2d398585;

  &:hover {
    color: white;
    font-size: 1.2em;
    border: 0.5px solid #959fdfd8;
    background: #2c3163;
    box-shadow: inset 0px 1px 0px 5px #a8b1efbb;
  }
}

.italic {
  font-style: italic;
  transition: ease-in-out 0.5s;
}

.bold {
  border-bottom: 1px solid darkgray;
}

.card {
  width: 60vmin;

  @media screen and (max-width: 450px) {
    width: 75vmin;
  }
}

@media screen and (max-width: 450px) {
  p {
    font-size: 3.5vmin;
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  // min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html,
body,
#root,
.App {
  height: 100%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
