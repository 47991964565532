$light-purple: rgb(188 176 242 / 82%);
$light-grey: rgb(188 187 189 / 83%);
$med-grey: rgb(255 255 255 / 59%);
$blue-purple: #4150ff;
$background: #121212;
$offwhite: #ffffffd4;

.dark-mode {
  * {
    background-color: $background;
  }

  .LandingPage {
    background-color: $background;
    color: $light-grey;
    transition: ease-in-out 0.5s;

    span {
      color: $offwhite;
    }

    .Typewriter > span {
      color: rgb(235 230 255 / 85%);
    }
  }

  .header {
    background-color: $background;
    color: $offwhite;
    border-bottom: 1px solid rgb(108 108 108 / 83%);
  }

  .button-wrapper {
    .navButton {
      background-color: $background;
      color: $offwhite;
      border-top: 1px solid $light-grey;
      border-left: 1px solid $light-grey;
      border-right: 1px solid $light-grey;
      position: relative;

      &:hover {
        color: $offwhite;
      }
    }
  }

  .font-face-gm,
  .home-button {
    color: rgb(194 199 255 / 80%);
  }
  .home-button {
    color: $light-grey;
  }

  #about-link {
    color: $offwhite;
  }

  .Card {
    border: 1px solid rgb(105 102 107 / 83%);

    .card-name {
      background-color: #252432;
      color: white;
    }
  }

  .card-button {
    background-color: rgba(255, 255, 255, 0.831372549);
    color: rgb(43, 38, 107);
    box-shadow: 0px 1px 0px 5px rgb(45 57 133 / 52%);

    &:hover {
      background-color: rgb(43 38 107);
      color: $offwhite;
      box-shadow: 0px 1px 0px 5px rgba(105, 121, 231, 0.77);
    }
  }

  .ArtCard,
  .ArtCard-Modal {
    border: 1px solid rgb(79 74 89 / 83%);
  }

  .active-image-container {
    border-bottom: 1px solid $light-grey;
  }

  .thumbnail-container {
    border: 1px solid rgb(79 74 89 / 83%);
  }

  .modal {
    color: $offwhite;

    button {
      color: $offwhite;
      border: 1px solid $light-grey;

      &:active {
        border: 1px solid white;
      }
    }
  }

  .MobileNav,
  .MobileNav button {
    background-color: $background;
    color: $light-grey;

    button {
      &:hover {
        color: $offwhite;
      }
    }
  }

  #sun-btn {
    &:hover {
      color: rgb(253, 237, 130);
    }
  }

  @keyframes slide-in-dark {
    0% {
      top: 100vh;
      opacity: 0%;
    }

    70% {
      top: 0vh;
      opacity: 50%;
    }

    100% {
      top: 2.5vh;
      opacity: 100%;
      border: 1px solid blue;
      padding: 40px 20px;
      border: 1px solid #3434a5;
      box-shadow: inset 0 0 0 2px #3434a4, 0px -10px 0 -3px #121212,
        0px -10px #40803e, 0px -20px 0 -3px #121212, 0px -20px #c0ab41,
        0px -30px 0 -3px #121212, 0px -30px #b77c51, 0px -40px 0 -3px #121212,
        0px -40px #b84444;
    }
  }

  .slide-in {
    animation: slide-in-dark 2.8s;
    animation-fill-mode: forwards;
  }

  .Dark-Mode .App {
    background: $background;
  }
}
