.dark-mode * {
  background-color: #121212;
}
.dark-mode .LandingPage {
  background-color: #121212;
  color: rgba(188, 187, 189, 0.83);
  transition: ease-in-out 0.5s;
}
.dark-mode .LandingPage span {
  color: rgba(255, 255, 255, 0.831372549);
}
.dark-mode .LandingPage .Typewriter > span {
  color: rgba(235, 230, 255, 0.85);
}
.dark-mode .header {
  background-color: #121212;
  color: rgba(255, 255, 255, 0.831372549);
  border-bottom: 1px solid rgba(108, 108, 108, 0.83);
}
.dark-mode .button-wrapper .navButton {
  background-color: #121212;
  color: rgba(255, 255, 255, 0.831372549);
  border-top: 1px solid rgba(188, 187, 189, 0.83);
  border-left: 1px solid rgba(188, 187, 189, 0.83);
  border-right: 1px solid rgba(188, 187, 189, 0.83);
  position: relative;
}
.dark-mode .button-wrapper .navButton:hover {
  color: rgba(255, 255, 255, 0.831372549);
}
.dark-mode .font-face-gm,
.dark-mode .home-button {
  color: rgba(194, 199, 255, 0.8);
}
.dark-mode .home-button {
  color: rgba(188, 187, 189, 0.83);
}
.dark-mode #about-link {
  color: rgba(255, 255, 255, 0.831372549);
}
.dark-mode .Card {
  border: 1px solid rgba(105, 102, 107, 0.83);
}
.dark-mode .Card .card-name {
  background-color: #252432;
  color: white;
}
.dark-mode .card-button {
  background-color: rgba(255, 255, 255, 0.831372549);
  color: rgb(43, 38, 107);
  box-shadow: 0px 1px 0px 5px rgba(45, 57, 133, 0.52);
}
.dark-mode .card-button:hover {
  background-color: rgb(43, 38, 107);
  color: rgba(255, 255, 255, 0.831372549);
  box-shadow: 0px 1px 0px 5px rgba(105, 121, 231, 0.77);
}
.dark-mode .ArtCard,
.dark-mode .ArtCard-Modal {
  border: 1px solid rgba(79, 74, 89, 0.83);
}
.dark-mode .active-image-container {
  border-bottom: 1px solid rgba(188, 187, 189, 0.83);
}
.dark-mode .thumbnail-container {
  border: 1px solid rgba(79, 74, 89, 0.83);
}
.dark-mode .modal {
  color: rgba(255, 255, 255, 0.831372549);
}
.dark-mode .modal button {
  color: rgba(255, 255, 255, 0.831372549);
  border: 1px solid rgba(188, 187, 189, 0.83);
}
.dark-mode .modal button:active {
  border: 1px solid white;
}
.dark-mode .MobileNav,
.dark-mode .MobileNav button {
  background-color: #121212;
  color: rgba(188, 187, 189, 0.83);
}
.dark-mode .MobileNav button:hover,
.dark-mode .MobileNav button button:hover {
  color: rgba(255, 255, 255, 0.831372549);
}
.dark-mode #sun-btn:hover {
  color: rgb(253, 237, 130);
}
@keyframes slide-in-dark {
  0% {
    top: 100vh;
    opacity: 0%;
  }
  70% {
    top: 0vh;
    opacity: 50%;
  }
  100% {
    top: 2.5vh;
    opacity: 100%;
    border: 1px solid blue;
    padding: 40px 20px;
    border: 1px solid #3434a5;
    box-shadow: inset 0 0 0 2px #3434a4, 0px -10px 0 -3px #121212, 0px -10px #40803e, 0px -20px 0 -3px #121212, 0px -20px #c0ab41, 0px -30px 0 -3px #121212, 0px -30px #b77c51, 0px -40px 0 -3px #121212, 0px -40px #b84444;
  }
}
.dark-mode .slide-in {
  animation: slide-in-dark 2.8s;
  animation-fill-mode: forwards;
}
.dark-mode .Dark-Mode .App {
  background: #121212;
}/*# sourceMappingURL=dark-mode.css.map */