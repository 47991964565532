.Card {
  width: 70%;
  border: 1px solid black;
  max-width: 600px;
  transition: ease-in-out 0.3s;

  &:hover {
    transform: scale(0.99);
  }

  .gifs {
    width: 100%;
    display: flex;
    cursor: pointer;
    &:hover {
      opacity: 120%;
    }
  }

  .card-name {
    font-family: "roboto-mono", "monospace";
    font-weight: 500;
    font-style: normal;
    padding: 10px 0;
    font-size: larger;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  .card-text {
    padding: 10px 50px 15px 50px;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2em;
    gap: 0.5em;
  }
}

@media only screen and (min-width: 800px) {
  .Card {
    width: 100%;
    height: 100%;
    max-width: 550px;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .card-bottom {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }
}
