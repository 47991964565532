.MobileNav {
  display: none;
  position: sticky;
  top: 0;
  background-color: white;

  button {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 2em;
    background-color: white;
    border: 0;
    border-bottom: 1px solid black;
    padding: 0.8em 0;
    color: black;
  }
}

@media only screen and (max-width: 700px) {
  .MobileNav {
    display: flex;
    button {
      color: black;
    }
  }
}

.hidden {
  display: none;
}

.dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.not-expanded {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid black;
  box-sizing: border-box;

  button {
    font-size: 1.5em;
    border-bottom: 0px;
    justify-content: flex-end;
    padding-right: 5%;
  }

  p {
    justify-content: flex-start;
    width: 100%;
    flex-grow: 2;
    font-size: 1.8em;
    text-align: left;
    padding-left: 5%;
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 5%;
    padding-right: 10px;
  }
}
