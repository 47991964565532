.ArtPage {
  display: flex;
  gap: 2em;
  flex-direction: row;
  padding-top: 5%;
  padding-left: 7%;
  padding-right: 7%;
  padding-bottom: 5%;
  justify-content: center;
}

.lr {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

@media only screen and (max-width: 600px) {
  .ArtPage {
    flex-direction: column;
    align-items: center;
  }

  .lr {
    width: 80vw;
  }
}
