.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  font-size: 1.5em;
  border-bottom: 1px solid black;
  position: sticky;
  background-color: white;

  .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 1px;
    gap: 0.8em;
  }
}

.home-button {
  font-family: "Victorianna";
  font-size: 3em;
  border: 0px;
  color: #3c00ff;
  cursor: pointer;
  bottom: -22px;
  position: relative;
  background: none;

  @media screen and (min-width: 800px) {
    font-size: 3.3em;
    padding-right: 0px;
  }
}

.button-wrapper .navButton {
  background: none;
  border: 0;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  font-size: 3vmin;
  font-family: "roboto-mono", monospace;
  font-weight: 300;
  font-style: normal;
  padding: 5px;
  cursor: pointer;

  &:hover {
    transform: translateY(2.4px);
    transition: ease-in-out 0.2s;
  }

  &:active {
    opacity: 60%;
  }
}

@media only screen and (max-width: 700px) {
  .header {
    display: none;
  }
}
