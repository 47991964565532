.modal {
  display: flex;
  justify-content: center;
  gap: 5%;
  align-items: flex-start;
  max-height: 100%;
  padding-left: 5%;
  padding-right: 5%;
  height: 100%;

  img {
    object-fit: contain;
    max-height: 70vh;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    max-width: 70%;
  }

  button {
    background: none;
    border: 1px solid black;
  }
}

.modal-img-container {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  .modal {
    flex-direction: column;
    align-items: center;
    gap: 20px;

    button {
      color: black;
      height: 100%;
    }

    .left {
      flex-direction: row;
      gap: 10%;
    }

    .left > button {
      height: 50%;
    }

    p {
      font-size: smaller;
    }

    img {
      max-height: 60vh;
    }
  }
}

@media only screen and (max-width: 450px) {
  .modal {
    padding-top: 5%;
  }
}
