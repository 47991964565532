.LandingPage {
  padding-top: 3.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 450px) {
  #greeting {
    font-size: 2.8em;
    font-weight: 900;
  }
}/*# sourceMappingURL=LandingPage.css.map */